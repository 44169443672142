import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";

const MeetOurTeem = () => {
  const data = [
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Ambert Daniel",
      desig: "CEO & Founder",
    },
  ];
  const [show, setShow] = useState(false);
  return (
    <div className=" h-fit  w-screen">
      <div className="flex flex-col justify-center text-5xl  ">
        <Typography className="text-6xl">Team Two </Typography>
        <Typography> Tronix Team Two </Typography>
      </div>
      <div className="   gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  place-content-center place-items-center ">
        {data.map((item) => {
          return (
            <div
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              className={` h-96 relative   w-96 flex flex-col items-center `}
            >
              <img
                src="image/ceo&founder.jpg  "
                className="rounded-full h-60 w-60 opacity-36   "
              />

              <Typography className="text-3xl mt-4">Ambert Daniel</Typography>
              <Typography className="text-sm mt-2 text-[#80838a]">
                CEO & Founder
              </Typography>
              {show && (
                <div className=" top-28 animate-fade-up absolute  text-white text-3xl animate-once animate-duration-700 animate-ease-out animate-normal">
                  shaikh vazir
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MeetOurTeem;
