import React from "react";
import { Homemain } from "./Homemain";
import Heromain from "../Hero/Heromain";
import Clients from "../Hero/Clients";
import { Service } from "../Services/Service";
import { Information } from "../Information/Information";
import Skill from "../ProvideSkill/Skill";
import { Studies } from "../Studies/Studies";
import QualityService from "../Services/QualityService";
import { Appointment } from "../Appointment/Appointment";
import TeamMember from "../TeamMember/TeamMember";
import PeopleAbout from "../PeopleTalk/PeopleAbout";
import { BlogPost } from "../BlogPost/BlogPost";

const HomeParent = () => {
  return (
    <div>
      <Homemain />
      <Heromain />
      <Clients />
      <Service />
      <Information />
      <Skill />
      <Studies />
      <QualityService />
      <Appointment />
      <TeamMember />
      <PeopleAbout />
      <BlogPost />
    </div>
  );
};

export default HomeParent;
