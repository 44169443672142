import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Top_headNavi from "./Navigation/Top_headNavi";
import Explore from "./Footer/Quick Links/Explore";
import { Footer } from "./Footer/Footer";
import MeetOurTeem from "./Footer/Quick Links/MeetOurTeem";
import NewsAndMedia from "./Footer/Quick Links/NewsAndMedia";
import OurProject from "./Footer/Quick Links/OurProject";
import Contact from "./Footer/Quick Links/Contact";
import Cyber_Security from "./Footer/Servicef/Cyber_Security";
import It_Managment from "./Footer/Servicef/It_Managment";
import Qa_Testing from "./Footer/Servicef/Qa_Testing";
import Infrastructure_Plan from "./Footer/Servicef/Infrastructure_Plan";
import IT_Consultant from "./Footer/Servicef/IT_Consultant";
import HomeParent from "./Home/HomeParent";

const Landing = () => {
  return (
    <BrowserRouter>
      <Top_headNavi />
      <Routes>
        <Route path="/" element={<HomeParent />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/meetourtemm" element={<MeetOurTeem />} />
        <Route path="/news&media" element={<NewsAndMedia />} />
        <Route path="/project" element={<OurProject />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cyberSecurity" element={<Cyber_Security />} />
        <Route path="/itmanagment" element={<It_Managment />} />
        <Route path="/qatesting" element={<Qa_Testing />} />
        <Route path="/infrastructureplan" element={<Infrastructure_Plan />} />
        <Route path="/itconsultant" element={<IT_Consultant />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Landing;
