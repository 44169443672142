import { Button } from "@material-tailwind/react";
import React from "react";

export const Service = () => {
  return (
    <main className="bg-[#f6f8fa]  w-screen h-fit  ">
      <div className="w-screen">
        <div className="">
          <span className="font-bold  text-[3rem] text-blue-900">.</span>
          <span font-bold className="text-sm font-semibold">
            MORE ABOUT OUR COMPANY{" "}
          </span>
          <span className="font-bold text-[3rem] text-blue-900">.</span>
        </div>
        <div>
          <span className=" text-4xl font-sans font-semibold ">
            Service We Provide
          </span>
        </div>
      </div>
      <div className=" w-screen h-auto grid grid-cols-2  p-4 ">
        <div className="bg-white-50 col-span-2 md:col-span-1 md:flex justify-center ">
          <div className=" col-span-1  bg-[#ffffff] pt-4 h-auto xl:ml-[12rem]  md:w-[32rem]">
            <div className="p-2">
              <img
                src="https://wptf.themepul.co/tronix/wp-content/uploads/2023/08/image-1.jpg"
                alt=""
              />
            </div>
            <div className="  p-7 w-84 md:text-start ">
              <h4 className="font-bold text-2xl ">IT Managments</h4>
              <span className="text-[#82858c] text-sm">
                Abitant porta litora pretium auctasor imperdiet quisque lacinia
                facisis taciti quam done fusce dignissim dapibus pellentesque
                interdum at nullam erat laoreet dis primis hac consequat turpis.
              </span>
              <br />
              <Button className="rounded-full mt-6 bg-[#0e59f2]">
                Start A Projects
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-white-50  bg-[#f6f8fa]  grid  mt-4 md:mt-0   col-span-2 md:col-span-1 md:w-[33rem]   grid-cols-1 md:grid-cols-2  ">
          <div className="bg-[#ffffff] rounded-md h-60 flex flex-col md:mt-1 p-5 md:mr-5  md:h-64 items-center justify-center">
            <img src="image/ui.png" className="h-16" alt="" />
            <span className="font-bold text-2xl mt-4">Digital Marketing</span>
            <p className="text-center text-sm mt-4 text-[#82858c]">
              Abitant porta litora pretium auctasor imperdiet.
            </p>
          </div>
          <div className="bg-[#ffffff] rounded-md  h-60  flex flex-col md:mt-1 p-10 mt-4  md:h-64  items-center justify-center">
            <img src="image/data.png" className="h-16" alt="" />
            <span className="font-bold text-2xl mt-4">Data Analysis</span>
            <p className="text-center text-sm mt-4  text-[#82858c]">
              They may offer a range of services, from project.
            </p>
          </div>
          <div className="bg-[#ffffff] rounded-md h-60  flex flex-col mt-5  md:mr-5 md:mt-11  md:h-64 items-center justify-center">
            <img src="image/system.png" className="h-16" alt="" />
            <span className="font-bold text-2xl mt-4">System Security</span>
            <p className="text-center text-sm mt-4  text-[#82858c] ">
              The project-based work to ongoing support and.
            </p>
          </div>
          <div className="bg-[#ffffff] rounded-md h-60 flex mt-4 mb-2 flex-col md:h-64 md:mt-11 items-center justify-center">
            <img src="image/forth.png" className="h-16" alt="" />
            <span className="font-bold text-2xl mt-4">UI/UX Design</span>
            <p className="text-center text-sm mt-4  text-[#82858c]">
              project work to ongoing support and maintenance.
            </p>
          </div>
        </div>
      </div>
    </main>
    // </div>0l\
  );
};
