import React from "react";

const It_Managment = () => {
  return (
    <>
      <div>
        <h3>It Managment</h3>
      </div>
    </>
  );
};

export default It_Managment;
