import { Button } from "@material-tailwind/react";
import React from "react";
import { FaCheckCircle, FaPlay } from "react-icons/fa";

const Heromain = () => {
  return (
    <div className="h-[60rem] lg:h-96 w-screen    flex flex-col lg:flex-row  md:mt-10  justify-center  ">
      <div className=" flex flex-col  sm:flex-row mt-72 md:mt-0 ">
        <div className="mt-1 flex sm:flex-row p-3 lg:flex-col  gap-6 ">
          <div className="">
            <img src="image/image1.png" alt="" className="h-56 w-48" />
          </div>
          <div className="">
            <img src="image/image2.png" className="h-56 w-48" />
          </div>
        </div>
        <div className="pl-10 md:pl-4 pt-6 md:pt-0">
          <img className="h-[30rem]" src="image/image3.png" alt="" />
        </div>
      </div>
      <div className="  h-96  w-[27rem] lg:pl-12 pl-2 mt-5 lg:mt-3 ">
        <div>
          <h3 className="text-left text-sm">WELCOME TO OUR COMPANY</h3>
        </div>
        <div className="flex">
          <span className=" w-[26rem] font-semibold text-start text-[2.4rem]">
            Make Your Business Great With Tronix
          </span>
        </div>
        <div className="pt-7 text-left whitespace-normal w-96 sm:w-full ">
          <span className="text-[#929393] text-sm ">
            An IT solution service company may serve clients from various
            industries such as healthcare, finance, education, and
            manufacturing. They may work on a project basis...
          </span>
        </div>
        <div className=" flex h-[50vh]  w-screen gap-2   flex-col lg:flex-row">
          <div className="text-left">
            <h1 className="text-[5rem] font-bold text-[#0e59f2] lg:mt-8">
              2001
            </h1>
            <p>STARTED JOURNEY</p>
            <Button className="rounded-full bg-[#0e59f2] mt-5  flex ">
              read more
            </Button>
          </div>
          <div className=" pt-10 lg:mt-5">
            <h3 className="flex mb-4">
              {" "}
              <FaCheckCircle className="text-[#0e59f2] mr-3" /> Emergency
              Solutions Anytime
            </h3>
            <h3 className="flex mb-4 ">
              {" "}
              <FaCheckCircle className="text-[#0e59f2] mr-3" /> Affordable price
              up to 2 years
            </h3>
            <h3 className="flex mb-4 ">
              {" "}
              <FaCheckCircle className="text-[#0e59f2] mr-3" /> Reliable &
              Experienced Team
            </h3>
            <span className="flex mt-5 lg:mt-10 ">
              <Button className="rounded-full mr-8  bg-[#0e59f2]  ">
                <FaPlay />
              </Button>
              <h3 className="text-sm font-bold">Watch More</h3>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heromain;
