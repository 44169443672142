import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";

export const TeamChild = ({ item }) => {
  return (
    <Card className="w-80 md:mb-10 mb-4 ">
      <CardHeader floated={false}>
        <img src={item.img} alt="profile-picture" className="h-96 w-96 "  />
      </CardHeader>
      <CardBody className="text-center">
        <Typography variant="h4" color="blue-gray" className="mb-2">
          {item.name}
        </Typography>
        <Typography color="blue-gray" className="font-medium" textGradient>
          {item.desig}
        </Typography>
      </CardBody>
      <CardFooter className="flex justify-center gap-7 pt-2">
        <Tooltip content="Like">
          <Typography
            as="a"
            href="#facebook"
            variant="lead"
            color="blue"
            textGradient
          ></Typography>
        </Tooltip>
        <Tooltip content="Follow">
          <Typography
            as="a"
            href="#twitter"
            variant="lead"
            color="light-blue"
            textGradient
          ></Typography>
        </Tooltip>
        <Tooltip content="Follow">
          <Typography
            as="a"
            href="#instagram"
            variant="lead"
            color="purple"
            textGradient
          ></Typography>
        </Tooltip>
      </CardFooter>
      <div className="flex justify-center gap-6 pb-8">
        <a href="">
          <FaFacebook className="h-10 w-10" />{" "}
        </a>
        <a href="">
          <FaInstagram className="h-10 w-10" />{" "}
        </a>
        <a href="">
          <FaTwitter className="h-10 w-10" />
        </a>
        <a href="">
          <FaLinkedin className="h-10 w-10" />
        </a>
      </div>
    </Card>
  );
};
