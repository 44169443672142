import React from "react";
import { Typography } from "@material-tailwind/react";

const OurProject = () => {
  const img = [
    {
      image: "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/4.jpg",
    },
    {
      image:
        "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/1-2.jpg",
    },
    {
      image:
        "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/bg-1-1.jpg",
    },
    {
      image:
        "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/asd-1.jpg",
    },
    {
      image:
        "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/aad-1.jpg",
    },
    {
      image:
        "https://wptf.themepul.co/tronix/wp-content/uploads/2023/06/asd-1.jpg",
    },
  ];
  return (
    <main>
      <div>
        <Typography className="text-5xl mt-10 font-semibold">
          Project
        </Typography>
        <Typography className="mt-5">Tronix Project</Typography>
      </div>
      <div className=" h-fit w-screen  p-14 grid grid-cols-1 sm:grid-col-2  place-items-center place-content-center gap-4 md:grid-cols-3">
        {img.map((item) => {
          return (
            <div className=" h-96 w-96 md:w-[30rem]">
              <img
                src={item.image}
                alt=""
                className="h-96 w-screen rounded-lg"
              />
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default OurProject;
