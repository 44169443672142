// import { Button } from "@material-tailwind/react";
// import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import React from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { SideNavi } from "./SideNavi";
import { MenuCustomList } from "./MenuCustomList";
import { IoIosCall } from "react-icons/io";
// import { MenuList } from "./MenuList";
import { MdOutlineWatchLater } from "react-icons/md";
import { Link } from "react-router-dom";

const Top_headNavi = () => {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <div className="relative">
      <div className="absolute mt-2 ml-12 hidden lg:block ">
        <Link to="/">
          <img
            src="image/PickLogo.png"
            className="h-28 mt-7 hover:bg-cyan-300 "
            alt=""
          />
        </Link>
      </div>
      <div>
        <div className="flex h-11  bg-white ">
          <div className=" text-cyan-50 text-end pr-10 w-28 md:w-full bg-[#082c6e] flex justify-end items-center   ">
            <Typography className="hidden   md:block text-sm ">
              We are reliaible Consistent IT Solution Team
            </Typography>
          </div>
          <div className="triangle-topleft"></div>
          <div className=" bg-[#ffffff]   w-full flex   items-center  justify-center  ">
            <p className="text-gray-500 text-md font-semibold md:ml-20  ">
              <MdOutlineWatchLater color="gray" className="inline-block" /> Mon
              - Sat 9.00 AM - 06.00 PM
            </p>
          </div>
        </div>

        <div className=" w-screen bg-[#ffffff] h-24 border-t-2 border-b-2 border-[#e3e8f4]">
          <div className="flex  relative">
            <div className="mt-5 lg:w-60">
              <img
                src="image/PickLogo.png"
                className="h-14 w-60  lg:hidden"
                alt=""
              />
            </div>
            <div className=" hidden lg:block mt-7 md:hidden">
              <MenuCustomList />
            </div>
            <div className=" absolute right-10 top-6">
              <IconButton
                className=" bg-cyan-50 lg:hidden"
                onClick={openDrawer}
              >
                <GiHamburgerMenu className="text-light-blue-900 text-2xl " />
              </IconButton>
            </div>
            <div className="xl:flex mt-7 ml-24  hidden ">
              <div>
                <IoIosCall className=" text-4xl rounded-full border border-cyan-400 text-cyan-400" />
              </div>
              <div className=" min-w-[10rem]">
                <p className="text-sm text-gray-500 ">Quick Call</p>
                <p>+917058657111</p>
              </div>
              <div>
                <Button className="rounded-full bg-[#0e59f2]">
                  Get a Quote
                </Button>
              </div>
            </div>
          </div>
          <Drawer open={open} onClose={closeDrawer} className="p-4">
            <div className="mb-6 flex items-center justify-between">
              <Typography variant="h5" color="blue-gray">
                PickupBiz
              </Typography>
              <IconButton variant="text" color="black" onClick={closeDrawer}>
                <h1>X</h1>
              </IconButton>
            </div>
            <div className="flex gap-2">
              <SideNavi />
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Top_headNavi;
