import React from "react";

const PeopleAbout = () => {
  return (
    <div className="h-screen w-screen mt-3  pt-16 bg-[#f1f1f3]  grid-rows-3 relative ">
      <div className="bg-[#b6dcfd] h-20 w-20 rounded-full absolute md:left-16 md:top-15 top-5 left-5 animate-jump animate-infinite animate-duration-[2000ms] animate-ease-in-out "></div>
      <div className=" h-20 w-20 rounded-full absolute right-28 md:top-24 hidden lg:block animate-jump animate-infinite animate-duration-[2000ms] animate-ease-in-out ">
        <img src="image/emp8.jfif" className="rounded-full" alt="" />
      </div>
      <div className=" h-20 w-20 rounded-full absolute right-96 top-96 hidden lg:block animate-jump animate-infinite animate-duration-[2000ms] animate-ease-in-out ">
        <img src="image/emp7.jfif" className="rounded-full" alt="" />
      </div>
      <div className="bg-[#dfddff] h-20 w-20 rounded-full absolute right-40 bottom-5 hidden lg:block animate-jump animate-infinite animate-duration-[2000ms] animate-ease-in-out "></div>
      <div className="  h-60 w-60 absolute  left-32 bottom-[5.5rem] hidden lg:block ">
        <img src="image/emp9.webp" alt="" />
      </div>
      <div className=" h-auto   md:h-32  justify-center items-center  flex flex-col ">
        <p className="m4 text-sm font-bold ">CLIENTS TALK</p>
        <p className="text-5xl font-Outfit font-bold m-4">
          People talk about Us
        </p>
      </div>
      <div className=" h-[35rem] md:h-[37rem] flex justify-center">
        <div className=" h-fit   w-screen md:w-[30rem] md:h-full p-3">
          <div className="flex justify-center p-10">
            <img
              src="image/emp6.jfif"
              alt=""
              className="h-40 w-40 rounded-full flex "
            />
          </div>
          <div>
            <h3 className="text-4xl font-semibold ">
              “Elit penatibus curae aucto”
            </h3>
            <p className="mt-7 text-[#999b9f]">
              penatibus varius dui nostra vehicula gravida congue, potenti etiam
              erat justo faucibus fusce quis nulla eu, dignissim eget posuere
              blandit curabitur porta inceptos. Inceptos faucibus fringilla
              pharetra mi suscipit curabitu{" "}
            </p>
          </div>
          <div className="pt-16 md:pt-10">
            <h3 className="text-2xl">Andrew Smith</h3>
            <p className="text-[#999b9f]">
              Designer at
              <span className="text-blue-400"> (Montan_Agency)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleAbout;
