import React from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Card,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

export const MenuCustomList = () => {
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const [openMenu5, setOpenMenu5] = React.useState(false);
  const [openMenu6, setOpenMenu6] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <div className="flex ml-40">
      <Menu open={openMenu1} handler={setOpenMenu1} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <b className=" text-sm">Home</b>
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu1 ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem>Analytics</MenuItem>
          <MenuItem>Reporting</MenuItem>
          <Menu
            placement="right-start"
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={15}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Project
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    openMenu ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList>
              <MenuItem>Project 1</MenuItem>
              <MenuItem>Project 2</MenuItem>
              <MenuItem>Project 3</MenuItem>
            </MenuList>
          </Menu>
          <MenuItem>More</MenuItem>
        </MenuList>
      </Menu>
      <Menu open={openMenu2} handler={setOpenMenu2} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <b className=" text-sm">About</b>
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu2 ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem>Analytics</MenuItem>
          <MenuItem>Reporting</MenuItem>
          <Menu
            placement="right-start"
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={15}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Project
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    openMenu ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList>
              <MenuItem>Project 1</MenuItem>
              <MenuItem>Project 2</MenuItem>
              <MenuItem>Project 3</MenuItem>
            </MenuList>
          </Menu>
          <MenuItem>More</MenuItem>
        </MenuList>
      </Menu>
      <Menu open={openMenu3} handler={setOpenMenu3} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <b className=" text-sm">Service</b>
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu3 ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem>Analytics</MenuItem>
          <MenuItem>Reporting</MenuItem>
          <Menu
            placement="right-start"
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={15}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Project
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    openMenu ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList>
              <MenuItem>Project 1</MenuItem>
              <MenuItem>Project 2</MenuItem>
              <MenuItem>Project 3</MenuItem>
            </MenuList>
          </Menu>
          <MenuItem>More</MenuItem>
        </MenuList>
      </Menu>
      <Menu open={openMenu4} handler={setOpenMenu4} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <b className=" text-sm">Page</b>
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu4 ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem>Analytics</MenuItem>
          <MenuItem>Reporting</MenuItem>
          <Menu
            placement="right-start"
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={15}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Project
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    openMenu ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList>
              <MenuItem>Project 1</MenuItem>
              <MenuItem>Project 2</MenuItem>
              <MenuItem>Project 3</MenuItem>
            </MenuList>
          </Menu>
          <MenuItem>More</MenuItem>
        </MenuList>
      </Menu>
      <Menu open={openMenu5} handler={setOpenMenu5} allowHover>
        <MenuHandler>
          <Button
            variant="text"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <b className=" text-sm">Blog</b>
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu5 ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem>Analytics</MenuItem>
          <MenuItem>Reporting</MenuItem>
          <Menu
            placement="right-start"
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={15}
          >
            <MenuHandler className="flex items-center justify-between">
              <MenuItem>
                Project
                <ChevronUpIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${
                    openMenu ? "rotate-90" : ""
                  }`}
                />
              </MenuItem>
            </MenuHandler>
            <MenuList>
              <MenuItem>Project 1</MenuItem>
              <MenuItem>Project 2</MenuItem>
              <MenuItem>Project 3</MenuItem>
            </MenuList>
          </Menu>
          <MenuItem>More</MenuItem>
        </MenuList>
      </Menu>
      <Menu>
        <Button
          variant="text"
          className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
        >
          <b className=" text-sm">Contact</b>
        </Button>
      </Menu>
    </div>
  );
};
