import React from "react";
import CountUp from "react-countup";

export const Information = () => {
  const MainImage = {
    backgroundImage:
      "url(https://cdn.azeusconvene.com/wp-content/uploads/Banner-Image_2023Q1-01Jan_Convene_GL-WebArticles_What-is-a-quorum-in-a-board-meeting.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 4.3,
  };
  return (
    <div
      className="bg-black h-screen  md:mt-0 w-screen xl:h-80 md:flex   grid gap-4 col-span-2 md:col-span-1   grid-cols-1 md:grid-cols-2 "
      style={MainImage}
    >
      <div className=" flex justify-center md:mt-24 md:ml-28 mt-16 h-16 ">
        <img src="image/Tmember.png" className="h-24 mr-4" alt="" />
        <div className="text-white mt-3">
          <h2 className="font-bold text-left text-5xl">
            <CountUp end={200} duration={5} />+
          </h2>
          <span className="text-sm">TEAM MEMBER</span>
        </div>
      </div>
      <div className=" justify-center flex md:mt-24 md:ml-28 mt-0 h-16 ">
        <img src="image/Cproject.png" className="h-24 mr-4" alt="" />
        <div className="text-white mt-3">
          <h2 className="font-bold text-left text-5xl">
            <CountUp end={10} duration={5} />+
          </h2>
          <span className="text-sm">COMPLETE PROJECT</span>
        </div>
      </div>
      <div className=" justify-center flex md:mt-24 md:ml-28  h-16 ">
        <img src="image/Waward.png" className="h-24 mr-4" alt="" />
        <div className="text-white mt-3">
          <h2 className="font-bold text-left text-5xl">
            <CountUp end={20} duration={5} />+
          </h2>
          <span className="text-sm">WINNING AWARD</span>
        </div>
      </div>
      <div className=" justify-center flex md:mt-24 md:ml-28  h-16 ">
        <img src="image/Creview.png" className="h-24 mr-4" alt="" />
        <div className="text-white mt-3">
          <h2 className="font-bold text-left text-5xl">
            <CountUp end={200} duration={5} />+
          </h2>
          <span className="text-sm">CLIENT REVIEW</span>
        </div>
      </div>
    </div>
  );
};
