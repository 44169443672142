import React from "react";

const Infrastructure_Plan = () => {
  return (
    <>
      <div>
        <h3>Infrastructure Plan</h3>
      </div>
    </>
  );
};

export default Infrastructure_Plan;
