import React from "react";

const NewsAndMedia = () => {
  return (
    <>
      <div>
        <h3>News And Media</h3>
      </div>
    </>
  );
};

export default NewsAndMedia;
