import React from "react";

const IT_Consultant = () => {
  return (
    <>
      <div>
        <h3>IT Consultant</h3>
      </div>
    </>
  );
};

export default IT_Consultant;
