import React, { useState, useEffect } from "react";
import "./Homemain.css";

const slides = [
  {
    image:
      "https://res.cloudinary.com/dinvpz6ti/image/upload/v1700306894/GettyImages-648950032_nrxpnm.jpg",
  },
  {
    image:
      "https://images.benefitspro.com/contrib/content/uploads/sites/412/2018/11/OfficeAcceptance.jpg",
  },
  {
    image:
      "https://www.liveabout.com/thmb/Ua341nNuemVBMXGJgYJ07mH0MFE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/530681921-56a4f2a63df78cf7728573ea.jpg",
  },
];

export const Homemain = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <>
      <div className="relative">
        <div className="flex w-screen h-screen bg-black ">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`w-full ${index === currentSlide ? "" : "hidden"}`}
            >
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="w-screen h-screen object-cover opacity-40"
              />
              <div
                className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-start opacity-0 fade-in-text ${
                  index === currentSlide ? "opacity-100" : ""
                }`}
              >
                <div className="text-white text-left pb-60 sm:pl-48 md:pl-30 pl-2">
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: 18 }}>
                      # PickupBiz Software{" "}
                    </p>
                  </div>
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: 55 }}>
                      Best IT Solutions
                    </p>
                  </div>
                  <div>
                    <p style={{ fontWeight: "bold", fontSize: 55 }}>
                      For Business
                    </p>
                  </div>
                  <div className="">
                    <button
                      className={`bg-[#0e59f2] text-white px-4 py-3 rounded-full mt-4 text-sm  font-bold   opacity-0 fade-in-2text ${
                        index === currentSlide ? "opacity-100" : ""
                      }`}
                      href="#"
                    >
                      Read more
                    </button>
                    <button
                      className={`bg-white text-[#0e59f2] px-6 py-3 ml-6 rounded-full mt-4 text-sm font-bold   opacity-0 fade-in-2text ${
                        index === currentSlide ? "opacity-100" : ""
                      }`}
                      href="#"
                    >
                      How it Work
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="absolute flex-col items-center justify-center space-y-4 bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {slides.map((_, index) => (
              <button
                style={{
                  border:
                    currentSlide === index
                      ? "2px solid rgb(19,100,193)"
                      : "2px solid white",
                  color: "white",
                  position: "relative",
                  bottom: 400,
                  left: 500,
                }}
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-4 h-4 rounded-full focus:outline-none ${
                  currentSlide === index
                    ? "bg-blue-800"
                    : "bg-rgba(255, 255, 255, 0.206)-400"
                } p-5 `}
              >
                <span
                  style={{
                    position: "relative",
                    bottom: 10,
                    right: 5,
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
