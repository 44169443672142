import React from "react";
import CountUp from "react-countup";
import { GrFormNextLink } from "react-icons/gr";

const QualityService = () => {
  return (
    <div className=" h-screen w-screen md:mt-0   md:justify-center grid-cols-2 md:gap-5  md:pt-24 md:pb-24  xl:flex justify-center">
      <div className="  h-[40rem] w-screen col-span-2  relative  md:h-auto    md:col-span-1  md:w-[42rem]   ">
        <div className=" border  rounded-[1rem]  absolute mt-20 md:mt-10 h-[29rem] md:h-[31rem] ml-5 w-[22rem] md:ml-56">
          <img src="image/emp4.jfif " className=" w-96" alt="" />
        </div>
        <div className=" absolute   rounded-[1rem]  w-56 h-72 top-52 md:h-96 left-5 md:top-36 md:w-72 ">
          <img src="image/emp5.jfif" alt="" className="w-96" />
        </div>
        <div className="bg-[#0e59f2]  rounded-[1rem] absolute pt-10 justify-center items-center h-44 w-64 mt-[28rem] md:h-36 md:mt-[27rem] md:ml-52 md:w-72">
          <p className="text-white text-4xl font-bold">
            <CountUp end={24} duration={5} />+
          </p>
          <p className="text-white font-semibold">Years Of Experience</p>
        </div>
      </div>
      <div className="col-span-2  text-start   p-4 gap-3 md:col-span-1 relative grid-cols-5 h-[35rem] md:h-auto w-screen md:w-[42rem]">
        <div className=" h-auto ">
          <span className="text-sm font-bold">WELCOME TO OUR COMPANY</span>
        </div>
        <div className=" h-auto mt-6 ">
          <span className="text-5xl">Get Our Best Quality Services</span>
        </div>
        <div className="  h-auto mt-6 ">
          <span className="text-[#a0a2a7]">
            Dolor sit amet consectetur adipiscing elit mauris posuere conubia
            dis luctus porta, netus primis metus faucibus onvallis scelerisque
            interdum nibh venenatis rutrum metus magnis, lobortis conubia vitae
            semper gravida
          </span>
        </div>
        <div className=" h-10 mt-12  flex justify-center items-center ">
          <div>
            <GrFormNextLink className="bg-[#0e59f2] text-white h-16 w-16 mr-4 rounded-full" />
          </div>
          <div className="mt-6">
            <span className="text-2xl font-semibold  ">
              Cloud Based Services
            </span>
            <p className="text-sm md:mt-5 text-[#a0a2a7]">
              Congue purus nam lacinia nec felis dictumst, mus eleifend
              habitasse proin elementum aliquet.
            </p>
          </div>
        </div>
        <div className=" h-10 mt-20  flex justify-center items-center ">
          <div>
            <GrFormNextLink className="bg-[#0e59f2] text-white h-16 w-16 mr-4 rounded-full" />
          </div>
          <div className="mt-6">
            <span className="text-2xl font-semibold   ">
              Expert Team Members
            </span>
            <p className="text-sm md:mt-5 text-[#a0a2a7]">
              Congue purus nam lacinia nec felis dictumst, mus eleifend
              habitasse proin elementum aliquet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityService;
