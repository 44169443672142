import { Button } from "@material-tailwind/react";
import React from "react";

const Skill = () => {
  return (
    <div className=" h-screen w-screen mt-20    md:mt-0 md:justify-center grid-cols-2 md:gap-5  md:pt-24 md:pb-24  xl:flex justify-center">
      <div className=" col-span-2 md:pt-9 md:h-auto  p-4 md:col-span-1 grid-cols-1 md:w-[42rem] w-screen h-auto  ">
        <div className="text-start mb-4">
          <span className="text-sm font-semibold">WELCOME TO OUR COMPANY</span>
        </div>
        <div className="text-start mt-1 mb-5 md:mr-5">
          <span className="text-5xl font-bold ">
            How Tronix Work & Process
          </span>
        </div>
        <div className="text-start mt-4 md:mr-5">
          <h3 className="text-base text-[#84878f]">
            Conubia fringilla vehicula ornare tempus viverra sollicitudin donec
            morbi penatibus, a vel aliquet felis lectus ridiculus risus mauris
            senectus fames, nunc sem luctus himenaeos purus aptent ac duis.
          </h3>
        </div>
        <div className="mt-14 md:flex  gap-5 text-start">
          <div className="flex items-center pb-7 md:p-0 ">
            <img
              src="image/provodeskill.png"
              className="h-24 pr-4"
              alt=""
            />
            <h3 className="text-[1.5rem]">Provide Skills Service</h3>
          </div>
          <div className="flex items-center">
            <img src="image/require.png" className="h-24 pr-4" />
            <h3 className="text-[1.5rem]">Urgent Support For Clients</h3>
          </div>
        </div>
        <div className="mt-20 text-start">
          <Button className="rounded-full bg-[#0e59f2]"> Read More</Button>
        </div>
      </div>
      <div className="col-span-2  md:col-span-1 relative grid-cols-1 h-auto md:h-auto w-screen md:w-[42rem]">
        <div className="absolute  left-5">
          <img
            className="h-28 w-28 animate-jump animate-infinite animate-duration-[3000ms] animate-ease-out"
            src="image/emp2.jfif"
            style={{ borderRadius: "100vh" }}
            alt=""
          />
        </div>
        <div className="absolute  top-14 left-24 md:left-36 md:top-10">
          <img
            className="h-72 w-72  md:h-[31rem] md:w-[31rem] "
            src="image/emp.jfif"
            style={{ borderRadius: "100vh" }}
            alt=""
          />
        </div>
        <div className="absolute top-56 left-6 md:top-[28rem]  ">
          <img
            className="h-60 w-60  md:h-28 md:w-28   animate-jump animate-infinite animate-duration-[2000ms] animate-ease-out "
            src="image/emp3.jfif"
            style={{ borderRadius: "100vh" }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Skill;
