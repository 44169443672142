import React from "react";

const Cyber_Security = () => {
  return (
    <>
      <div>
        <h3>Cyber security</h3>
      </div>
    </>
  );
};

export default Cyber_Security;
