import React from "react";

const imgs = [
  "image/mico.png",
  "image/cdac1.png",
  "image/jpmorgan.png",
  "image/sulzer.png",
  "image/reliance.png",
];
const Clients = () => {
  return (
    <div className=" w-screen  mt-[33rem] lg:mt-48 ">
      <div className="md:hidden">
        <span>----------------OUR TRUSTED CLIENTS ------------------</span>
      </div>
      <div className="hidden lg:block ">
        <span>
          -----------------------------------------------------------------------------------------------------------------OUR
          TRUSTED CLIENTS
          ----------------------------------------------------------------------------------------------------------------
        </span>
      </div>
      <div className="flex w-[80vw] ml-2 overflow-auto   md:mx-auto  pt-6 md:flex md:justify-center  scroll-smooth md:overflow-scroll:none ">
        {imgs.map((item, index) => {
          return (
            <img
              className="h-24 w-[40rem] mb-5 md:w-96   shadow-sm  border ml-4 "
              key={index}
              src={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Clients;
