import React from "react";

const Qa_Testing = () => {
  return (
    <>
      <div>
        <h3>Qa Testing</h3>
      </div>
    </>
  );
};

export default Qa_Testing;
