import React from "react";
import { SlCalender } from "react-icons/sl";
import { FaRegUser } from "react-icons/fa";

export const BlogPost=()=>{
    const BlogData=[
        {Bimage:"https://img.freepik.com/premium-photo/happy-businesspeople-having-meeting-brainstorming-boardroom-start-up-firm_232070-13426.jpg",date:"June 4, 2023",sponser:"by PDAC",detail:"Your Business Safe Ensure High Availability.",info:"Read More"},
        {Bimage:"https://media.istockphoto.com/id/669887538/photo/colleagues-using-tablet-pc-in-textile-factory.jpg?s=612x612&w=0&k=20&c=dsJt2E34DcDMn6C2CpVTTCOymq7_WecrOrDwceqJ9j0=",date:"June 4, 2023",sponser:"by PDAC",detail:"Data Backup and Recovery Best Practices Small",info:"Read More"},
        {Bimage:"https://c0.wallpaperflare.com/preview/423/32/865/adult-beard-blur-books.jpg",date:"June 4, 2023",sponser:"by PDAC",detail:"Small Business Disaster Recovery Planning",info:"Read More"},
    ]
    return( 
        <div className=" w-screen h-screen pt-24">
            <div className="">
                <span className="text-sm font-semibold md:text-[1rem]"><span className="text-[5rem] md:mr-2 text-[#3047aa]">.</span>BLOG UPDATES<span className="md:ml-2 text-[5rem] text-[#3047aa]">.</span></span>
                <h1 className=" md:mt-4 md:text-[3rem] text-[2.80rem] font-bold  mt-3">Our Blog Posts</h1>
            </div>
            <div className=" md:mt-14 md:ml-32 md:flex md:flex-row md:absolute mt-16">
                {
                    BlogData.map((item)=>
                    <div className=" md:relative md:m-3 h-fit m-2 mt-10">
                    <img className="md:w-[27rem] md:h-[24rem] w-full pl-0 h-[21.30rem] md:rounded-t-[1.80rem] rounded-t-2xl" src={item.Bimage}  alt="" />
                    <div className="bg-white border-solid border-2  md:h-[14rem] md:w-[27rem] md:rounded-b-[1.80rem] rounded-b-2xl text-left  flex pb-11 pt-8 pl-8">
                       <div className="md:ml-2">
                         <div className=" flex flex-row md:w-[16rem] w-60">
                         <SlCalender className="text-sm mt-1 text-[#0f59f2] font-bold"/> 
                            <span className="ml-2 md:text-[1.10rem] text-gray-600">{item.date}</span>
                            <FaRegUser className="text-sm ml-8 mt-1 text-blue-800 font-bold"/>
                            <span className="ml-1 md:text-[1.10rem] text-gray-600">{item.sponser}</span>
                         </div>
                         <div className=" md:w-80 w-80  mt-3">
                            <span className="md:text-[1.30rem] text-2xl">{item.detail}</span>
                         </div>
                         <div className="mt-3">
                         <span className="text-[#0f59f2]">{item.info}</span>
                         </div>
                       </div>
                    </div>
                </div>
                    )
                }
            </div>
        </div>
    )
}