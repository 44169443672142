import React from "react";
import { FaTwitter } from "react-icons/fa6";
import { RiFacebookFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="bgimage h-auto w-screen mt-[75rem] md:mt-36 pb-9 pl-3 pt-7">
      <div className=" md:flex md:ml-[8rem] md:mt-12">
        <div>
          <div className="text-white text-left h-14 ">
            <h2 className=" text-[1.20rem] font-bold ">About Us</h2>
            <span className=" mb-10 font-bold">______</span>
          </div>
          <div className="text-white text-left w-80 mt-8">
            <span>
              An IT consultancy can help you assess your technology needs and
              develop a technology strategy that aligns with your business
            </span>
          </div>
          <div className=" text-left mt-8">
            <button className="text-white rounded-full border-solid border p-3">
              <RiFacebookFill />
            </button>
            <button className="text-white rounded-full border-solid border p-3 ml-3">
              <FaTwitter />
            </button>
            <button className="text-white rounded-full border-solid border p-3 ml-3">
              <FaInstagram />
            </button>
            <button className="text-white rounded-full border-solid border p-3 ml-3">
              <BiLogoLinkedin />
            </button>
          </div>
        </div>
        <div className="md:ml-6">
          <div className="text-white text-left md:mt-0 h-14 mt-8">
            <h2 className=" text-[1.20rem] font-bold ">Quick Links</h2>
            <span className=" mb-10 font-bold">______</span>
          </div>
          <div className=" text-left  mt-6 text-white md:w-80">
            <h2 className="mt-2">
              <Link to="/explore">Explore</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/meetourtemm">Meet Our Team</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/news&media">News & Media</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/project">Our Project</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/contact">Contact</Link>
            </h2>
          </div>
        </div>
        <div>
          <div className="text-white md:mt-0 text-left h-14 mt-8">
            <h2 className=" text-[1.20rem] font-bold ">Services</h2>
            <span className=" mb-10 font-bold">______</span>
          </div>
          <div className=" text-left mt-6 text-white md:w-80">
            <h2 className="mt-2">
              <Link to="/cyberSecurity">Cyber Security</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/itmanagment">IT Management</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/qatesting">QA & Testing</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/infrastructureplan">Infrastructure Plan</Link>
            </h2>
            <h2 className="mt-2">
              <Link to="/itconsultant">IT Consultant</Link>
            </h2>
          </div>
        </div>
        <div>
          <div className="text-white text-left h-14 mt-8 md:mt-0">
            <h2 className=" text-[1.20rem] font-bold ">Get In Touch</h2>
            <span className=" mb-10 font-bold">______</span>
          </div>
          <div className="text-white text-left md:w-80  mt-8">
            <span>
              Curabitur aliquet quam posuere blandit ellentesque insdorci ipsum
              id orci porta dapibus
            </span>
          </div>
          <div className="relative mt-8">
            <input
              type="text"
              className="border mr-9 rounded-[0.50rem] py-2 h-[3.5rem] md:h-[4.3rem] px-3 pr-11 w-[23rem] md:w-[18rem] focus:outline-none focus:border-blue-500"
              placeholder="Enter Your Email"
            />
            <button
              type="button"
              style={{ boxShadow: "0px 0px 10px 0px #0f59f2" }}
              className="absolute  top-1/2 md:w-32 md:right-12 right-10 w-28 transform -translate-y-1/2 bg-[#0f59f2] text-white py-2 px-4 md:p-3 rounded-[1rem]"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white md:flex md:w-[80rem] md:ml-[8rem]  md:p-2 w-[23rem] text-white  text-left mt-20 rounded-[0.50rem] p-3">
        <img
          src="https://pickupbiz.com/static/media/pickupbizzlogo.fbf33cad.jpg"
          className="h-16 ml-6"
          alt=""
        />
        <h3 className="ml-8 text-[#a7a9ae] md:mt-5 md:ml-[50rem]">
          Copyright © 2023. All Rights Reserved.
        </h3>
      </div>
    </div>
  );
};
