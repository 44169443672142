import React from "react";
import { BlogPost } from "../../BlogPost/BlogPost";
import { Typography } from "@material-tailwind/react";

const Explore = () => {
  return (
    <>
      <div className="p-10">
        <h1 className="text-6xl font-semibold md:mb-5">Service</h1>
        <h3>Tronix Service</h3>
      </div>
      <div className="h-fit w-screen  flex justify-center items-center">
        <div className=" w-[80vw] h-fit   place-items-center gap-4 grid grid-cols-1 xl:grid-cols-4 p-4  ">
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
          <div className=" h-auto w-72 text-start border rounded-xl shadow-xl  p-10  ">
            <div className=" h-120 w-20">
              <img src="image/ser1.png" alt="" />
            </div>
            <div>
              <h1 className="mt-10 text-2xl">Technology Solution</h1>
            </div>
            <div>
              <h3 className="mt-8 text-base">
                Risus turpis id mauris tellus ultricies cras nulla.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#4f5fff]  h-fit w-screen grid xl:grid-cols-2 md:p-32 p-6  ">
        <div className=" h-fit w-fit  text-start  md:pl-20 ">
          <Typography className="text-white mb-11">
            WELCOME TO OUR COMPANY
          </Typography>
          <Typography className="text-white text-6xl mb-11">
            World’s No.1 Technology Solution Provider.
          </Typography>
          <Typography className="text-white mb-11">
            The goal of IT services is to provide efficient and effective
            technology solutions that help businesses achieve their objectives.
          </Typography>
          <Typography className="text-white">Watch Video</Typography>
        </div>
        <div className=" h-96 w-screen  flex items-center  ">
          <img
            className="h-fit w-96 md:h-96 md:w-fit"
            src="image/sevlogo.png"
            alt=""
          />
        </div>
      </div>
      <div className="h-screen w-screen ">
        <BlogPost />
      </div>
    </>
  );
};

export default Explore;
