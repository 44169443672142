import React from "react";

export const Appointment=()=>{
    const MainImage={
        backgroundImage:'url(https://enterprisetalk.com/wp-content/uploads/2023/10/How-to-Bring-Your-Workforce-Together-Using-Culture.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity:4.3,
      
    }
    return(
        <div style={MainImage} className="bg-black md:h-[19rem] md:mt-0  h-[31rem] w-screen mt-[22rem] pt-28 pl-6">
            <div className=" text-left md:flex md:flex-row flex-col">
                <span className="text-white md:ml-[7rem] font-bold md:text-[3rem] text-[2.80rem]">Have any project in mind?</span>
                <button className="text-center md:ml-[30rem] md:text-xl md:mt-3 md:w-[17rem] md:p-5 mt-10 w-56 p-4 rounded-full text-white  bg-[#0f59f2]">Make An Appointment</button>
            </div>
        </div>
    )
}