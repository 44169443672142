import React from "react";

export const Studies=()=>{

    const CaseData=[
        {image:"https://st5.depositphotos.com/1000975/66095/i/450/depositphotos_660956188-stock-photo-young-male-employee-unhappy-excessive.jpg",work:"Cyber Security Services",desig:"Software"},
        {image:"https://img.freepik.com/free-photo/young-happy-businessman-using-computer-office-celebrating-good-news-there-are-people-background_637285-30.jpg",work:"Knowledge Based Chat",desig:"Technology"},
        {image:"https://img.freepik.com/free-photo/business-man-owner-company-office_1303-16046.jpg",work:"Software Cloud Services",desig:"Software"},
        {image:"https://img.freepik.com/free-photo/smiling-brunette-manager-with-crossed-arms_1139-307.jpg",work:"Cross Industry Expertise",desig:"Marketing"},
    ]
    return(
        <div className="bg-[#0f1c2f]   h-auto  w-screen md:mt-0   mt-96">
            <div className=" flex justify-center  pt-20 flex-col  mt-12">
                <span className="text-white text-sm pb-4">OUR PROJECT</span>
                <h1 className="text-white font-bold text-5xl pb-20">Recent Case Studies</h1>
            </div>
            <div className="md:flex">
            {
                CaseData.map((item)=>
                <div className="    h-fit m-2">
                    <img className="md:h-96 md:w-96 w-full h-full rounded-t-2xl" src={item.image}  alt="" />
                    <div className="bg-white md:h-40 md:w-96 rounded-b-2xl text-left  flex pb-11 pt-8 pl-8">
                        <div className="w-64 h-1">
                            <h6 className="font-bold text-2xl ">{item.work}</h6>
                            <span className="text-gray-600">{item.desig}</span>
                        </div>
                        <button className="bg-blue-900  mr-5 h-14 w-14 text-3xl text-white rounded-full  font-bold">+</button>
                    </div>
                </div>
                )
            }
            </div>
        </div>
    )
}