import React from "react";
import { TeamChild } from "./TeamChild";

const TeamMember = () => {
  const data = [
    {
      img: "image/ceo&founder.jpg",
      name: "Amjad Khan",
      desig: "CEO & Founder",
    },
    {
      img: "image/digitermarketer.jpg",
      name: "Waseem Khan",
      desig: "Network Engineer",
    },
    {
      img: "image/ceo&founder.jpg",
      name: "Shaikh Vazir",
      desig: "Software Engineer",
    },
    {
      img: "image/Tejas.jpg",
      name: "Tejas Bhatt",
      desig: "Software Trainee",
    },
  ];
  return (
    <div className="h-fit  items-center  md:mt-0 w-screen  flex flex-col justify-center">
      <div className=" mt-20">
        <p className="mb-5 font-semibold text-sm">TEAM MEMBERS</p>
      </div>
      <div>
        <p className="text-5xl mb-12 font-semibold ">Expert Team Members</p>
      </div>
      <div className="md:flex gap-5">
        {data.map((item) => {
          return <TeamChild item={item} />;
        })}
      </div>
    </div>
  );
};

export default TeamMember;
